@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	[data-aos] {
		opacity: 1 !important;
		transition: none !important;
		transform: none !important;
	}
}

img {
	height: auto;
	max-width: 100%;

	&.full-width {
		max-width: 100vw;
		width: 100%;
	}
}

.is-empty {
	&:empty {
		display: none;
	}
}

.none,
.hidden {
	display: none;
}

.container {
	max-width: 100%;
	padding-left: 3rem;
	padding-right: 3rem;

	@media (min-width: 1440px) {
		// max-width: 1366px;
		padding-left: 4rem;
		padding-right: 4rem;
	}

	@include media-breakpoint-down(lg) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	@include media-breakpoint-down(md) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.row {
	&.small-gutters {
		margin-right: -0.5rem;
		margin-left: -0.5rem;

		> .col,
		> [class*="col-"] {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
		}
	}
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;

	&-track {
		background: colors(9);
	}

	&-thumb {
		background: colors(2);

		&:hover {
			background: colors(1);
		}
	}
}

:focus {
	// outline-color: transparent;
}

html,
body {
	overflow-x: hidden;
	overflow-y: auto;
	// width: 100%;
	// height: auto;

	font-family: $baseFont;
	font-size: $baseSize;
	color: colors(5);

	scroll-behavior: smooth;
	scrollbar-color: colors(2) colors(9);
	scrollbar-width: thin;

	@include media-breakpoint-up(sm) {
		padding-right: 0 !important;
	}
}

iframe {
	max-width: 100%;
}

a {
	position: relative;
	color: colors(1);
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: colors(2);
		text-decoration: underline;
	}

	&.text {
		&-primary {
			&:hover,
			&:focus {
				color: colors(2) !important;
			}
		}
	}
}

.full-container {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	overflow-x: hidden;
}

.modal {
	-webkit-overflow-scrolling: touch;
	// background-color: rgba(colors(5), $o04);

	&-header {
		line-height: 1;
	}

	&-content {
		border-color: transparent;
		border-radius: 5px;

		.close {
			position: absolute;
			right: -1rem;
			top: -1rem;
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
			color: colors(3);
			background: colors(2);
			text-align: center;
			opacity: 1 !important;
			z-index: 2;
			font-size: rem(15);

			&:hover {
				background: colors(1);
			}
		}
	}

	&-body {
		border-radius: 0;
	}

	@include media-breakpoint-up(sm) {
		padding-right: 0 !important;
	}

	@include media-breakpoint-down(xs) {
		&-content {
			.close {
				right: -0.5rem;
				top: -0.5rem;
			}
		}
	}
}

.bg-img {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&.attach {
		background-attachment: fixed;
	}

	&.contain {
		background-size: contain !important;
	}

	&.auto {
		background-size: auto !important;
	}

	&.top {
		background-position: center top !important;
	}

	&.bottom {
		background-position: center bottom !important;
	}

	&.start {
		background-position: left center !important;
	}

	&.end {
		background-position: right center !important;
	}
}

.form-group {
	position: relative;

	&-icon {
		position: absolute;
		top: 0.85rem;
		left: 1.2rem;
		color: colors(8);
		font-size: rem(12);

		+ input,
		+ textarea {
			padding-left: 2.6rem !important;
		}
	}

	label {
		&:not(.is-invalid):not(.custom-control-label) {
			@extend .font-medium;
			@extend .text-lg;
		}

		&.is-invalid {
			color: colors(10);
			margin-bottom: 0;
		}
	}

	&.invalid-feedback {
		color: colors(10);
		@extend .font-medium;
		@extend .text-base;
	}
}

.form-control {
	@extend .font-regular;
	font-size: rem(13);
	color: colors(4);
	border-radius: 0;
	border-bottom-color: colors(9);
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;

	&::placeholder {
		color: colors(8);
		font-family: inherit;
		font-size: inherit;
		text-transform: capitalize;
	}

	&:hover,
	&:active,
	&:focus {
		// box-shadow: 0 0 0 0.2rem transparent;
	}

	&[type="date"],
	&[type="text"],
	&[type="number"],
	&[type="password"],
	&[type="email"],
	&[type="file"],
	&[type="tel"] {
		padding: 0.6rem 0.8rem;
		height: auto;
		min-width: 5rem;
	}

	&.custom-select {
		padding: 0.6rem 0.8rem;
		height: auto;

		> option[value=""] {
			text-transform: capitalize;
		}
	}

	&.is-invalid {
		&:not(.custom-select) {
			border-color: colors(10) !important;
			padding-right: calc(1.5em + 0.75rem) !important;
			background-image: $svg-invalid !important;
		}

		&.custom-select {
			border-color: colors(10) !important;
		}
	}
}

textarea.form-control {
	padding: 0.6rem 0.8rem;
	height: auto;
	border: 1px solid colors(9);
}

.custom-control {
	&.custom-radio {
		> label {
			&.is-invalid {
				&:not(.custom-control-label) {
					position: absolute;
					bottom: 0.5rem;
				}

				~ label {
					&.custom-control-label {
						padding-bottom: 2rem;
					}
				}
			}
		}
	}

	&-input {
		&:checked {
			~ .custom-control {
				&-label {
					&::before {
						// border-color: colors(1);
						// background-color: colors(1);
					}
				}
			}
		}
	}
}

.line-height-reset {
	line-height: 1 !important;
}

.spacing {
	&-normal {
		letter-spacing: 0;
	}

	&-wide {
		letter-spacing: 0.025em;
	}

	&-wider {
		letter-spacing: 0.05em;
	}

	&-widest {
		letter-spacing: 0.1em;
	}
}

.btn {
	@extend .font-medium;
	font-size: rem(13);
	padding: 0.8rem 1.5rem;
	text-decoration: none;
	// line-height: 1;
	letter-spacing: 1px;
	text-transform: uppercase;
	border-radius: 5px;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		// box-shadow: 0 0 0 0.2rem transparent;
	}

	&-primary {
		&:hover,
		&:active,
		&:focus {
			border-color: colors(2);
			background-color: colors(2);
		}
	}

	&-dark {
		&:hover,
		&:active,
		&:focus {
			// border-color: colors(1);
			// background-color: colors(1);
		}
	}

	&-sm {
		padding: 0.4rem 1rem;
	}

	&-lg {
		padding: 1rem 2.5rem;
	}

	&-round {
		// border-radius: 50rem;
	}

	&-flat {
		border-radius: 0;
	}

	&-single {
		padding: 0.7rem 1rem;

		&.btn-lg {
			padding: 1rem 1.3rem;
		}
	}

	&-icon,
	&-plus,
	&-back {
		padding-left: 3rem;
		position: relative;

		&::after {
			content: "";
			@extend %fal;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 1.5rem;
		}

		&.btn-sm {
			padding-left: 2rem;

			&::after {
				left: 0.5rem;
			}
		}

		&.btn-lg {
			padding-left: 3.5rem;

			&::after {
				left: 2rem;
			}
		}

		&.btn-bold {
			&::after {
				@extend %fas;
			}
		}
	}

	&-icon {
		&::after {
			content: $fa24;
		}
	}

	&-plus {
		&::after {
			content: $fa24;
		}
	}

	&-back {
		&::after {
			content: $fa02;
		}
	}
}

.hover {
	&-primary {
		&:hover {
			color: colors(1) !important;
		}
	}

	&-secondary {
		&:hover {
			color: colors(2) !important;
		}
	}

	&-dark {
		&:hover {
			color: colors(5) !important;
		}
	}
}

.big-title {
	position: relative;
	color: colors(1);
	@extend .text-4xl;
	text-transform: capitalize;
	letter-spacing: $l01;
	margin-top: 0;
	margin-bottom: 2rem;
}

.small-title {
	position: relative;
	color: colors(5);
	@extend .text-sm;
	letter-spacing: $l01;
	text-transform: uppercase;
	padding-left: 5rem;
	margin-top: 0;
	margin-bottom: 0;
	line-height: 1;
	text-decoration: none;

	&::before {
		content: "";
		position: absolute;
		width: 4rem;
		height: 2px;
		background: colors(1);
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&:active,
	&:focus,
	&:hover {
		color: inherit;
		text-decoration: none;
  	}

  	&[href] {
		@extend .font-regular;
		padding-left: 4rem;

		&::before {
			width: 3rem;
			height: 1px;
			background: currentColor;
		}

		&:active,
		&:focus,
		&:hover {
			color: colors(1);
		}
  	}
}

.invalid-g-recaptcha {
	display: none;
}

.g-recaptcha {
	&.captcha-center {
		> div {
			margin: 0 auto;
		}
	}

	@include media-breakpoint-down(xs) {
		> div {
			margin: 0 auto;
		}
	}

	@media (max-width: 375px) {
		transform: scale(0.77);
		transform-origin: 0 0;
	}
}

.fa {
	&-vertical-text-bottom {
		vertical-align: text-bottom;
	}
}

#tinymce,
.mce-content-body {
	.row {
		margin: 0;
	}

	.color-c03,
	.text-white {
		color: black !important;
	}

	&.row {
		margin: 0;
	}

	&.color-c03,
	&.text-white {
		color: black !important;
	}
}

.gradient {
	&-dark {
		&-bottom {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
		}
	}
}

.text-shadow {
	text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
}

.object-fit {
	&-cover {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	&-contain {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}

.pagination {
	margin: 1rem 0 3rem;
	border-radius: 0;
    align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	.page-link {
		@extend .font-bold;
		font-size: rem(18);
		color: colors(8);
		line-height: 1;
		border: 0;
		border-radius: 0 !important;
		// border-bottom: 2px solid transparent;
		margin: 0 1rem;
		letter-spacing: 1px;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		text-decoration: none !important;


		&:focus,
		&:hover {
			color: colors(5);
			box-shadow: none;
			background-color: colors(3);
		}

		@include media-breakpoint-down(lg) {
			margin: 0 0.8rem;
		}

		@include media-breakpoint-down(md) {
			margin: 0 0.6rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: rem(16);
			margin: 0 0.4rem;
		}

		@include media-breakpoint-down(xs) {
			margin: 0 0.2rem;
		}
	}

	.page-item {
		&.active {
			.page-link {
				@extend .font-bold;
				color: colors(1);
				// border-color: colors(1);
				background-color: colors(3);
			}
		}

		&.disabled {
			.page-link {
				color: colors(8);
				// border-color: transparent;
			}
		}
	}
}