$theme-colors: (
	"primary": #0003C2,
	"secondary": #FD0000,
	"dark": #020202,
);

@import "~bootstrap/scss/bootstrap";
@import "~rfs/scss";

@import "./uno-variable";
@import "./uno-mixin";
@import "./uno-custom";

#wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: 0;
	overflow-x: hidden;
}

#header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: $zindex-fixed;
	background-color: colors(3);
	border-top: 3px solid colors(1);
	transition: background 0.5s linear;
	box-shadow: 0px 3px 6px -3px rgba(colors(5), $o03);

	// .header-icon {
	// 	color: colors(3);
	// }

	// &.sticky {
	// 	color: colors(4);
	// 	background-color: colors(3);

	// 	.header-icon {
	// 		color: colors(4);
	// 	}
	// }

	@include media-breakpoint-down(md) {
		left: 0;
		padding: 1rem 0;

		// &.product {
		// 	.header-icon {
		// 		color: colors(4);
		// 	}
		// }
	}
}

#logo {
	img {
		width: auto;
		max-height: 43px;

		@include media-breakpoint-down(md) {
			max-height: 39px;
		}
	}
}

#navigation {
	color: colors(4);
	text-align: right;
	@include transition(0.25s all);

	// &.sticky,
	// &.product {
	// 	color: colors(4);
	// }

	ul {
		display: inline-flex;
		align-items: center;

		li {
			display: inline-block;
			position: relative;
			margin: 0 1.5rem 0 0;
			padding: 1.7rem 0;
			@include transition(0.25s all);

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&.active {
				> a {
					color: colors(1);
				}
			}

			&:hover {
				> a {
					color: colors(1);
				}

				> ul {
					opacity: 1;
					visibility: visible;
					transform: translate(-50%, 0);
					@include transition(0.25s all);
				}
			}

			a {
				@extend .font-medium;
				font-size: rem(13);
				color: inherit;
				text-decoration: none;
				text-transform: uppercase;
				letter-spacing: 1px;

				&.btn-search {
					color: colors(1);

					&:hover {
						color: colors(2);
					}
				}

				&.btn-contact {
					color: colors(3);
					background-color: colors(1);
					border-radius: 5px;
					padding: 0.6rem 1.7rem;

					&:hover {
						background-color: colors(2);
					}
				}
			}
		}

		ul {
			position: absolute;
			top: 100%;
			left: 50%;
			z-index: $zindex-popover;
			visibility: hidden;
			opacity: 0;
			transform: translate(-50%, 0.5rem);
			@include transition(0.25s all);

			display: block;
			width: 200px;
			background-color: colors(5);
			padding: 1rem 0;
			text-align: center;

			li {
				width: 100%;
				margin: 0;
				padding: 0;
				display: block;

				&:hover {
					> a {
						color: colors(2);
						text-decoration: none;
					}
				}

				a {
					display: block;
					position: relative;
					color: colors(3);
					padding: 0.5rem 1.5rem;
					line-height: 1.2;
					text-transform: unset;
				}

				ul {
					top: -1rem;
					left: 150%;
					margin-left: 2px;
				}
			}
		}
	}
}

#searchModal {
	.modal-content {
		background: transparent;

		#keyword {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			padding: 1.5rem 2rem 1.5rem;
		}

		.btn {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
}

#banner {
	position: relative;
	z-index: $zindex-sticky;
	background: colors(5);

	.banner {
		&-slide {
			position: relative;
			display: flex;
			align-items: center;
			// height: 100vh;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o03);

			height: calc(100vh - #{$headerHeight});

			@include media-breakpoint-down(md) {
				height: calc(100vh - #{$headerHeightMobile});
			}
		}

		&-overlay {
			display: none;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: linear-gradient(
				0deg,
				rgba(colors(3), 1) 0%,
				rgba(colors(7), 0) 50%,
				rgba(colors(7), 0) 80%,
				rgba(colors(3), 1) 100%
			);
			z-index: 0;
		}

		&-content {
			position: relative;
			max-width: 50%;
			z-index: 2;
			text-shadow: -1px 1px 5px rgba(colors(5), $o04);

			@include media-breakpoint-down(lg) {
				max-width: 70%;
			}

			@include media-breakpoint-down(md) {
				max-width: 80%;
			}

			@include media-breakpoint-down(sm) {
				max-width: 88%;
			}

			@include media-breakpoint-down(xs) {
				max-width: 100%;
			}
		}

		&-dots {
			position: absolute;
			left: 3rem;
			bottom: 4rem;

			@media (min-width: 1440px) {
				left: 4rem;
			}

			@include media-breakpoint-down(lg) {
				left: 2rem;
			}

			@include media-breakpoint-down(md) {
				left: 1rem;
			}

			.swiper-pagination-progressbar {
				height: 3px;
				width: 10%;
				left: 0;
				top: 0;
				background: colors(3);

				.swiper-pagination-progressbar-fill {
					background: colors(2);
				}

				@include media-breakpoint-down(sm) {
					width: 20%;
				}

				@include media-breakpoint-down(xs) {
					width: 30%;
				}
			}
		}

		&-nav {
			display: none;

			position: absolute;
			left: 0;
			right: 0;
			top: 50%;

			.swiper-button-prev,
			.swiper-button-next {
				right: 1rem;
				left: auto;
				background-image: none;
				background: rgba(colors(3), $o03);
				color: colors(6);
				height: 40px;
				width: 40px;
				border-radius: 100%;
				border: 1px solid colors(3);
				text-align: center;
				margin-top: 0px;
				transform: translateY(-50%);
				line-height: 38px;

				&:hover {
					background: colors(1);
					border-color: colors(1);
				}
			}

			.swiper-button-next {
				top: calc(50% - 1.8rem);
			}

			.swiper-button-prev {
				top: calc(50% + 1.8rem);
			}

			@include media-breakpoint-down(xs) {
				display: none;
			}
		}

		&-scroll {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 2.5rem;
			z-index: 3;
			text-align: right;

			&-button {
				display: inline-block;
				color: colors(3);
			}
		}

		&-btn {
			display: inline-block;
			padding-top: 0.8rem;
			padding-bottom: 0.8rem;
			padding-left: calc(1rem + 45px);
			padding-right: 1rem;
			color: colors(2);
			font-size: rem(14);
			@extend .font-semibold;
			text-transform: capitalize;
			border-radius: 24px;
			text-shadow: none;
			text-decoration: none;
			background-color: transparent;
			transition: padding 0.5s ease, color 0.5s ease, background 0.5s ease;

			&::before {
				position: absolute;
				left: 0;
				top: 0;
				content: "\f178";
				@extend %fal;
				height: 45px;
				width: 45px;
				line-height: 45px;
				text-align: center;
				background-color: colors(2);
				border-radius: 50%;
				color: colors(3);
				font-size: rem(15);
				transition: left 0.5s ease;
			}

			&:hover {
				color: colors(3);
				padding-left: 1.5rem;
				padding-right: calc(0.5rem + 45px);
				background-color: colors(2);

				&::before {
					left: calc(100% - 45px);
				}
			}
		}
	}
}

// #latest {
// 	position: absolute;
// 	z-index: $zindex-sticky + 1;
// 	bottom: 0;
// 	right: 0;
// 	width: 40%;

// 	@media (max-width: 1440px) {
// 		width: 50%;
// 	}

// 	@include media-breakpoint-down(lg) {
// 		width: 55%;
// 	}

// 	@include media-breakpoint-down(md) {
// 		width: 60%;
// 	}

// 	@include media-breakpoint-down(sm) {
// 		position: relative;
// 		bottom: unset;
// 		right: unset;
// 		width: 100%;
// 	}

// 	.swiper-wrapper,
// 	.swiper-slide {
// 		height: auto;
// 	}

// 	.latest {
// 		&-news {
// 			display: block;
// 			height: 100%;
// 			text-decoration: none;

// 			&-title {
// 				overflow: hidden;
// 				max-height: 54px;
// 				margin-bottom: 0;
// 			}

// 			img {
// 				max-height: 160px;
// 			}

// 			@include media-breakpoint-down(sm) {
// 				&-title {
// 					overflow: unset;
// 					max-height: unset;
// 				}

// 				img {
// 					max-height: unset;
// 				}
// 			}
// 		}

// 		&-background {
// 			&-blur {
// 				background-color: rgba(colors(4), 0.4);
// 				backdrop-filter: blur(5px);
// 			}

// 			&-dark {
// 				background-color: rgba(colors(5), 0.5);
// 			}
// 		}

// 		&-nav {
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: center;
// 			align-items: center;
// 			height: 100%;

// 			.swiper-button-prev,
// 			.swiper-button-next {
// 				position: relative;
// 				right: unset;
// 				left: unset;
// 				top: unset;
// 				margin: 0 auto;
// 				width: 100%;
// 				height: auto;
// 				padding: 0.5rem 0;
// 				font-size: rem(18);
// 				color: colors(3);

// 				&::after {
// 					content: unset;
// 				}

// 				&:hover {
// 					color: colors(1);
// 				}
// 			}

// 			@include media-breakpoint-down(xs) {
// 				flex-direction: row-reverse;
// 				justify-content: flex-start;

// 				.swiper-button-prev,
// 				.swiper-button-next {
// 					margin: 0;
// 					width: auto;
// 					font-size: rem(22);
// 					padding: 1rem;
// 				}
// 			}
// 		}
// 	}
// }

#innerBanner {
	position: relative;
	background-color: colors(5);
	z-index: $zindex-sticky;

	#breadcrumb .breadcrumb {
		padding-top: 0.5rem;
		padding-bottom: 0;
		color: colors(3);
		justify-content: center;
	}

	.inner-banner {
		&-slide {
			height: 300px;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o04);
		}

		&-title {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			z-index: 1;
		}
	}
}

#breadcrumb {
	text-align: center;

	.breadcrumb {
		margin: 0;
		background: none;
		padding: 1rem 0;
		color: colors(5);
		font-size: rem(11);
		letter-spacing: 1px;
		text-transform: capitalize;
		border-radius: 0;
		flex-wrap: nowrap;
		align-items: center;
		// justify-content: center;

		> li {
			position: relative;
			padding: 0.5rem 0;
			line-height: 1;
			white-space: nowrap;
			// text-overflow: ellipsis;
			// overflow: hidden;

			+ li {
				padding-left: 2rem;

				&:before {
					content: "\f105";
					@extend %fal;
					position: absolute;
					left: 0.8rem;
					top: 0.3rem;
					color: inherit;
					font-size: rem(14);
					margin: 0;
					padding: 0;
				}
			}

			&:last-child {
			  	// display: none;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	a {
		color: inherit;
		font-family: inherit;
		text-decoration: none;
		text-transform: capitalize;

		&:hover {
			color: colors(1);
			text-decoration: none;
		}
	}
}

#content {
	position: relative;
	flex-grow: 1;

	margin-top: $headerHeight;

	@include media-breakpoint-down(md) {
		margin-top: $headerHeightMobile;
	}
}

#innerPage {
	position: relative;
	z-index: $zindex-sticky;
	padding: 2rem 0;

	.inner {
		&-main-services {
			@extend .full-container;
			margin-top: -2rem;
			margin-bottom: 3rem;
			padding: 3rem 0;
			background-color: colors(6);

			.card {
				height: 100%;

				&-img-top {
					border-radius: 0;
					height: 250px;
					object-fit: cover;
				}

				&-body {
					display: flex;
					flex-direction: column;

					p {
						flex-grow: 1;
					}
				}
			}
		}
	}
}

#home {
	position: relative;

	#homeAnchor {
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;
		padding-top: $headerHeight;
		z-index: -1;

		@include media-breakpoint-down(md) {
			padding-top: $headerHeightMobile;
		}
	}

	.home {
		&-about {
			&-background {
				position: absolute;
				top: 0;
				left: -4rem;
				z-index: -1;
				height: 100%;

				img {
					object-fit: contain;
					height: 100%;
				}
			}

			&-gif {
				padding-top: 4rem;
				padding-bottom: 4rem;

				img {
					display: block;
					border-radius: 5px;
					box-shadow: 0px 3px 6px rgba(colors(5), 0.1);
					margin: 0 auto;
					overflow: hidden;
				}
			}

			&-button {
				display: block;

				span {
					color: colors(4);
					opacity: 0.2;
					transition: opacity 0.5s ease, color 0.5s ease, transform 0.5s ease;
				}

				&:hover {
					span {
						color: colors(2);
						opacity: 1;
						transform: translateX(1rem);
					}
				}
			}

			&-card {
				position: relative;
				display: block;

				&.right {
					margin-top: 3rem;

					.home-about-card-content {
						&::before {
							background-color: colors(1);
						}
					}
				}

				&-thumb {
					width: 280px;
					height: 280px;

					img {
						object-fit: cover;
						height: 100%;
						width: 100%;
					}

					@include media-breakpoint-down(lg) {
						width: 250px;
						height: 250px;
					}

					@include media-breakpoint-down(md) {
						width: 280px;
						height: 280px;
					}

					@include media-breakpoint-down(sm) {
						width: 240px;
						height: 240px;
					}

					@include media-breakpoint-down(xs) {
						width: 200px;
						height: 200px;
					}
				}

				&-content {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					@extend .font-semibold;
					font-size: rem(14);
					color: colors(3);
					padding: 2rem 2.5rem 1rem 1rem;
					@extend .gradient-dark-bottom;
					z-index: 1;
					overflow: hidden;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						width: 100%;
						height: 100%;
						background-color: colors(2);
						z-index: -1;
						transition: transform 0.5s ease;
						transform: translateY(calc(100% - 0.4rem));
					}

					&::after {
						content: "\f178";
						@extend %far;
						color: colors(3);
						position: absolute;
						right: 1rem;
						bottom: 1rem;
					}
				}

				&:hover {
					.home-about-card-content {
						&::before {
							transform: translateY(1rem);
						}
					}
				}
			}
		}

		&-category {
			padding-top: 5rem;
			padding-bottom: 1rem;
			margin-bottom: 6rem;
			background-color: colors(13);

			&-wrapper {
				position: relative;
				padding-left: 3rem;
				padding-right: 3rem;
				margin-bottom: -4.5rem;

				@include media-breakpoint-down(sm) {
					padding-left: 2rem;
					padding-right: 2rem;
				}
			}

			&-slider {
				padding-top: 1rem;

				.swiper-slide {
					height: auto;

					&:hover {
						> a {
							transform: translateY(-1rem);
						}
					}
				}
			}

			&-box {
				display: block;
				padding: 1rem 1.2rem;
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				height: calc(100% - 1rem);
				background-color: colors(3);
				border-radius: 5px;
				box-shadow: 0px 3px 6px rgba(colors(5), 0.1);
				color: colors(5);
				transition: transform 0.5s ease;

				&:hover {
					text-decoration: none;
					color: colors(1);
				}
			}

			&-nav {
				.swiper-button {
					&-prev,
					&-next {
						top: calc(50% - 1rem);
						font-size: rem(30);
						margin-top: 0;
						color: colors(1);

						&::after {
							content: unset;
						}

						&:hover {
							color: colors(5);
						}
					}

					&-prev {
						left: 0;
					}

					&-next {
						right: 0;
					}
				}
			}
		}

		&-featured {
			padding-top: 8rem;
			padding-bottom: 4rem;
			padding-left: 5rem;

			@include media-breakpoint-down(lg) {
				padding-left: 4rem;
			}

			@include media-breakpoint-down(md) {
				padding-left: 1rem;
			}

			@include media-breakpoint-down(sm) {
				padding-top: 5rem;
			}

			@include media-breakpoint-down(xs) {
				padding-top: 3rem;
			}

			&-main {
				max-width: 500px;
				max-height: 500px;

				&::before {
					content: "";
					position: absolute;
					left: -2rem;
					bottom: -5rem;
					// width: 650px;
					// height: 650px;
					width: 130%;
					padding-top: 130%;
					// height: 150%;
					// max-width: 650px;
					// max-height: 650px;
					background-color: #F8FAFF;
					border-radius: 50%;
					z-index: -1;
				}
			}

			&-more {
				padding-left: 2rem;

				@include media-breakpoint-down(md) {
					padding-left: 1rem;
				}

				@include media-breakpoint-down(sm) {
					padding-left: 0;
					padding-top: 3rem;
				}
			}

			&-link {
				display: block;
				width: 45px;
				height: 45px;
				line-height: 47px;
				text-align: center;
				border-radius: 50%;
				color: colors(5);
				border: 1px solid colors(5);
				font-size: rem(16);
				background-color: colors(3);
				transition: background 0.5s ease;

				&:hover {
					color: colors(3);
					border-color: colors(2);
					background-color: colors(2);
				}
			}

			&-slider {
				.swiper-slide {
					height: auto;
				}
			}

			&-pagination {
				.swiper-pagination-progressbar {
					position: relative;
					height: 3px;
					width: 100%;
					left: 0;
					top: 0;
					background: colors(6);

					.swiper-pagination-progressbar-fill {
						background: colors(5);
					}

					// @include media-breakpoint-down(sm) {
					// 	width: 20%;
					// }

					// @include media-breakpoint-down(xs) {
					// 	width: 30%;
					// }
				}
			}

			&-nav {
				display: flex;
				align-items: center;

				.swiper-button {
					&-prev,
					&-next {
						position: relative;
						top: unset;
						left: unset;
						right: unset;
						font-size: rem(30);
						margin-top: 0;
						color: colors(9);
						width: 44px;

						&::after {
							content: unset;
						}

						&:hover {
							color: colors(5);
						}
					}
				}
			}

			&-card {
				width: 230px;
				padding-bottom: 0.5rem;
			}
		}
	}
}

#ads {
	img {
		max-height: 200px;
	}
}

#footer {
	position: relative;
	background-color: colors(12);
	border-bottom: 5px solid colors(2);

	.footer {
		&-menu {
			div {
				> a[href] {
					color: colors(3);

					&:active,
					&:hover,
					&:focus {
						color: colors(1);
					}
				}
			}
		}

		&-column {
			&-left {
				padding-top: 3rem;
				padding-bottom: 3rem;
				padding-right: 5rem;
				border-right: 1px solid #7183A0;

				@include media-breakpoint-down(lg) {
					padding-right: 3rem;
				}

				@include media-breakpoint-down(md) {
					padding: 2rem 1rem;
				}

				@include media-breakpoint-down(sm) {
					border-right: 0;
				}
			}

			&-right {
				padding-top: 3rem;
				padding-bottom: 3rem;
				padding-left: 5rem;

				@include media-breakpoint-down(lg) {
					padding-left: 3rem;
				}

				@include media-breakpoint-down(md) {
					padding: 2rem 1rem;
				}
			}
		}

		&-backtop {
			display: inline-block;
			position: absolute;
			z-index: 1;
			bottom: -5px;
			right: 2rem;
			text-decoration: none;
			border-top-right-radius: 19px;
			border-top-left-radius: 19px;
			background-color: colors(2);
			color: colors(3);
			padding-top: 0.8rem;
			padding-bottom: 0.6rem;
			padding-left: 1rem;
			padding-right: 1rem;
			font-size: rem(15);
			transition: bottom 0.5s ease;

			&:hover {
				bottom: 0;
				color: colors(1);
			}
		}
	}
}

#newsletter {
	position: relative;
	border-bottom: 1px solid colors(3);
	.newsletter {
		&-icon {
			color: colors(2);
			position: absolute;
			left: 0;
			top: 50%;
			z-index: 1;
			transform: translateY(-50%);
		}

		&-field {
			@extend .text-md;
			color: colors(3);
			padding: 1rem 2rem;
			border-color: transparent;
			background-color: transparent;
			letter-spacing: 1px;

			&:-webkit-autofill {
				-webkit-text-fill-color: colors(3) !important;
				transition: background-color 5000s ease-in-out 5000s;

				&:hover,
				&:focus,
				&:active {
					transition: background-color 5000s ease-in-out 5000s;
				}
			}

			&::placeholder {
				color: colors(3);
			}
		}

		&-button {
			color: colors(3);
			background-color: transparent;
			padding-right: 0;

			&:hover {
				color: colors(2);
			}
		}
	}
}

#socialMedia {
	display: inline-flex;
	flex-wrap: wrap;
	margin-right: -0.5rem;
	margin-left: -0.5rem;
	align-items: center;

	.social-media {
		&-link {
			flex: 0 0 auto;
			max-width: 100%;
			position: relative;
			display: block;
			color: colors(3);
			background: transparent;
			text-decoration: none;
			text-align: center;
			width: 2.5rem;
			height: 2.5rem;
			margin: 0 0.5rem;
			border-radius: 100%;

			.fa,
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: rem(14);
			}

			&:hover,
			&:focus,
			&:active {
				color: colors(2) !important;
			}
		}
	}
}

#sitemap {
	.sitemap {
		&-title {
			display: inline-block;
			margin-bottom: 2rem;

			~ ul {
				list-style: none;
				padding: 0;
			}
		}
	}

	ul {
		padding: 0.2rem 1rem;

		li {
			display: block;
			color: colors(5);
			text-transform: uppercase;

			a {
				display: inline-block;
				font-family: inherit;
				font-size: inherit;
				color: inherit;
				text-decoration: none;
				padding: 0.2rem 0;

				&:hover,
				&:focus,
				&:active {
					color: colors(2);
					text-decoration: none;
				}
			}
		}
	}
}

#mobileMenu {
	@extend .font-bold;
	color: colors(5);
	text-transform: uppercase;

	&:not(.mm) {
		display: none;
	}

	&.mm {
		li:before {
			border-color: colors(1);
			opacity: 1;
		}

		&::before {
			border-color: colors(3);
			z-index: 1;
		}

		&:after {
			box-shadow: 0 1px 0 0 rgba(colors(1), .625), 0 1px 0 0 rgba(colors(1), $o01);
			color: colors(3);
			opacity: 1;
			background-color: colors(1);
		}
	  }

	a {
		@extend .font-bold;
		text-transform: none;
		text-decoration: none;

		&:hover,
		&:active,
		&:focus {
			color: colors(2);
			text-decoration: none;
		}
	}
}

#news,
#pages {
	img {
		width: 100%;
		height: 280px;
		object-fit: cover;
	}
}

#listing {
	.listing {
		&-filter {
			border-bottom: 1px solid colors(9);
			margin-bottom: 2.5rem;

			&-button {
				display: block;
				width: 100%;
				padding-left: 0.5rem;
				padding-right: 3rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				background-color: colors(3);
				text-align: left;
				border: 0;

				&::before {
					content: "";
					position: absolute;
					top: 1rem;
					right: 0;
					height: calc(100% - 2rem);
					width: 1px;
					background-color: colors(9);
				}

				&::after {
					position: absolute;
					top: 47%;
					right: 1.5rem;
					transform: translateX(-50%);
					margin-left: 0;
					vertical-align: unset;
				}

				span {
					@extend .font-medium;
					text-transform: uppercase;
					letter-spacing: 1.5px;
				}

				&:hover,
				&[aria-expanded="true"] {
					color: colors(2);
				}

				@include media-breakpoint-down(xs) {
					border-top: 1px solid colors(9);
					padding-right: 1rem;

					&::before {
						content: unset;
					}

					&::after {
						right: 0;
					}
				}
			}

			&-menu {
				margin-top: 0;
				border: 0;
				border-radius: 0;
				background-color: colors(5);
				width: 100%;
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
				max-height: 200px;
				overflow: auto;

				a {
					color: colors(3);
					@extend .font-medium;
					font-size: rem(13);

					&:hover,
					&:focus {
						color: colors(2);
						background-color: transparent !important;
					}
				}
			}
		}
	}
}

#product {
	position: relative;
	padding-top: 2rem;
	overflow: hidden;

	#breadcrumb {
		position: relative;
		display: inline-block;

		.breadcrumb {
			padding-top: 0;
			padding-bottom: 2rem;
			color: colors(1);
		}

		a {
			&:hover {
				color: colors(2);
			}
		}

		&::before {
			content: "";
			position: absolute;
			z-index: -1;
			top: -2rem;
			left: -4rem;
			right: -3rem;
			height: 200vh;
			background-color: #F4F7FF;

			@include media-breakpoint-down(sm) {
				right: -2rem;
			}

			@include media-breakpoint-down(xs) {
				right: -1rem;
			}
		}
	}

	.product {
		&-gallery {
			padding-bottom: 2rem;
			background-color: colors(3);
			border-radius: 5px;
			box-shadow: 0px 2px 6px rgba(colors(5), 0.2);

			&-box {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 500px;
				padding: 2rem;

				img {
					max-height: 100%;
				}

				@include media-breakpoint-down(lg) {
					// height: 400px;
				}

				@include media-breakpoint-down(md) {
					height: 400px;
				}

				@include media-breakpoint-down(sm) {
					height: 300px;
				}
			}

			&-dots {
				.swiper-pagination {
					position: relative;
					display: block;

					&-bullet {
						background-color: colors(1);
						margin: 0 0.5rem;

						&-active {
							border-radius: 5px;
							width: 26px;
						}
					}
				}
			}
		}

		&-content {

		}

		&-overview {
			&-card {
				border-bottom: 1px solid colors(9);
			}

			&-header {
				border-bottom: 1px solid colors(9);
				margin-bottom: -1px;

				button {
					display: flex;
					align-items: center;
					padding: 0.8rem 0.5rem;
					width: 100%;
					background-color: colors(3);
					border: 0;
					color: colors(4);
					@extend .font-medium;
					font-size: 13px;

					i {
						color: inherit;
						transition: transform 0.5s ease;
					}

					&:hover {
						color: colors(5);
					}

					&[aria-expanded="true"] {
						@extend .font-bold;
						color: colors(5);

						i {
							transform: rotate(180deg);
						}
					}
				}
			}

			&-body {
				padding-top: 1rem;
				padding-bottom: 1rem;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				background-color: colors(3);
			}
		}
	}
}

#related {
	// margin-top: 3rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: colors(3);

	.related {
		&-title {
			position: relative;
			@extend .text-4xl;
			@extend .font-extrabold;
			text-align: center;
			text-transform: uppercase;
			padding-bottom: 1rem;
			margin-bottom: 3rem;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				height: 3px;
				width: 4rem;
				background-color: colors(2);
			}
		}
	}
}

.product-card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	height: 100%;
	text-decoration: none;
	color: colors(5);
	background-color: colors(3);
	border: 1px solid colors(9);
	border-radius: 5px;
	overflow: hidden;

	&:hover {
		.product-card-content {
			color: colors(3);
			background-color: colors(1);
			border-color: colors(1);
			text-decoration: none;

			> span {
				color: colors(3);
			}
		}
	}

	&-thumbnail {
		flex: 0 1 auto;
		width: 100%;
		padding: 1rem;
		height: 280px;
		background-color: colors(3);

		display: inline-flex;
		align-items: center;
		justify-content: center;

		img {
			max-height: 100%;
		}
	}

	&-content {
		position: relative;
		flex: 1 0 auto;
    	min-height: 1px;
		padding: 1rem;
		padding-top: 0.8rem;
		// padding-right: 3rem;
		background-color: colors(3);
		border-top: 1px solid colors(9);
		transition: background 0.5s ease, border 0.5s ease;

		> span {
			color: colors(4);
		}

		// &::before {
		// 	content: "\f178";
		// 	@extend %far;
		// 	position: absolute;
		// 	z-index: 1;
		// 	right: 1rem;
		// 	bottom: 1rem;
		// 	color: colors(3);
		// 	font-size: rem(12);
		// }
	}
}

#category {
	.product-card {
		&-thumbnail {
			padding: 0;
		}

		&-content {
			img {
				height: 50px;
				margin-bottom: 1rem;
			}
		}

		&:hover {
			.product-card-content {
				color: colors(1) !important;
				background-color: colors(13);
				border-color: colors(13);
			}
		}
	}
}