// font awesome type
%fa {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

%fal {
	@extend %fa;
	font-family: "Font Awesome 5 Pro";
	font-weight: 300;
}

%far {
	@extend %fa;
	font-family: "Font Awesome 5 Pro";
	font-weight: 400;
}

%fas {
	@extend %fa;
	font-family: "Font Awesome 5 Pro";
	font-weight: 900;
}

%faf {
	font-family: "Font Awesome 5 Free" !important;
}

%fab {
	@extend %fa;
	font-family: "Font Awesome 5 Brands";
	font-weight: 400;
}

%fad {
	@extend %fa;
	font-family: "Font Awesome 5 Duotone";
	font-weight: 400;
}

// opacity
$o01: 0.1;
$o02: 0.2;
$o03: 0.3;
$o04: 0.5;
$o05: 0.6;
$o06: 0.8;
$o07: 0.9;

// letter-spacing
$l01: 1px;
$l02: 2px;
$l03: 3px;

// borders
$b01: 1px;
$b02: 2px;
$b03: 3px;

// font-awesome icons
$fa01: "\f002";
$fa02: "\f104";
$fa03: "\f105";
$fa04: "\f3c5";
$fa05: "\f0e0";
$fa06: "\f095";
$fa07: "\f1ac";
$fa08: "\f39e";
$fa09: "\f0e1";
$fa10: "\f0da";
$fa11: "\f106";
$fa12: "\f30b";
$fa13: "\f111";
$fa14: "\f00d";
$fa15: "\f015";
$fa16: "\f019";
$fa17: "\f1b0";
$fa18: "\f068";
$fa19: "\f0d7";
$fa20: "\f0d8";
$fa21: "\f0c9";
$fa22: "\f30a";
$fa23: "\f1ad";
$fa24: "\f067";
$fa25: "\f03a";
$fa26: "\f00c";
$fa27: "\f071";
$fa28: "\f06a";
$fa29: "\f406";
$fa30: "\f290";
$fa31: "\f30c";
$fa32: "\f0d2";
$fa33: "\f1de";
$fa34: "\f0dc";
$fa35: "\f004";
$fa36: "\f007";
$fa37: "\f4be";
$fa38: "\f187";
$fa39: "\f304";
$fa40: "\f6aa";

// social colors
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5b9a68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #eb4823;
$yahoo: #7b0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;

// alert colors
$success-bg: #d4edda;
$success: #155724;
$error-bg: #f8d7da;
$error: #721c24;
$warning-bg: #fff3cd;
$warning: #856404;

$hover-color: #FD0000;
$border-color: #EAEAEA;

$uno-colors: (#0003C2, #FD0000, #ffffff, #777777, #020202, #f6f6f6, #969696, #C9C9C9, #E5E5E5, #FD0000, #FAFAFA, #001840, #EFF6FF);

$svg-invalid: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");

$baseFont: "Poppins-Regular";
$baseSize: 15px;

$headerHeight: 76px;
$headerHeightMobile: 72px;
